.Container
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  width: 150px
.Title
  color: #8b8ba7
  font-size: 0.9em
  line-height: 1.3em
  font-weight: 700
  text-align: center