.TechInfo
  position: relative
  display: flex
  flex-direction: row
  box-sizing: border-box
.Card
  margin: 0 20px 20px 0
.CardContent
  display: flex
  flex-direction: row
  flex-wrap: wrap
.Cell
  display: flex
  justify-content: center
  align-items: center
  width: 150px
  height: 150px
  margin: 5px
  transition: 300ms
  &:hover
    background-color: #eeeeee
