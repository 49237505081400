.Wrapper
  display: flex
  flex-wrap: wrap
  margin-top: 20px
.LoaderWrapper
  width: 100%
  height: 100%
  display: flex
  justify-content: center
  align-items: center
